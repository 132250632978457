<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container import-user-page"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto">
        <div class="wrapper-ifrem custom-wrapper-ifrem">
          <div class="row paj-custom-row">
            <div
              v-show="!isUploadSuccess"
              class="col-xl-12 col-lg-12 col-md-12 pb-4 wrapper-dashboard custom-wrapper-dashboard"
            >
              <div
                class="card-custom card-stretch gutter-b panel panel-default title-wrapper"
              >
                <div
                  class="d-flex align-items-center flex-wrap mr-2 text-title-point-adjustment"
                >
                  <img
                    class="mr-2"
                    :src="require('@/assets/images/fil022.svg')"
                    alt=""
                  />
                  {{ lbl['import-user-menu'] }}
                </div>
                <notice
                  v-if="isBeeBanefit"
                  :messege="beeBenefitNoticeMessage"
                  icon="red_notice"
                />
              </div>
            </div>
            <div
              v-show="!isUploadSuccess"
              class="col-xl-12 col-lg-12 col-md-12 wrapper-point-adjustment"
            >
              <div class="d-flex wrapper-select-header">
                <div class="select-header active">
                  {{ lbl['import-user-menu'] }}
                </div>
                <div
                  @click="goto('ImportUserLog')"
                  class="select-header select-hd-right"
                >
                  {{ lbl['import-log-menu'] }}
                </div>
                <div
                  @click="goto('ImportUserSearch')"
                  class="select-header select-hd-right"
                >
                  {{ lbl['import-search-menu'] }}
                </div>
              </div>
              <div class="wrapper-content">
                <div class="around-content-import">
                  <div v-if="!isProgress">
                    <drop class="wrapper-upload-excel-file" @drop="handleDrop">
                      <div class="wrapper-sub-upload-excel-file">
                        <img
                          :src="require('@/assets/images/cloud_upload@2x.png')"
                          alt=""
                          class="img-cloud-upload"
                          :draggable="false"
                        />
                        <div class="text-1">
                          {{ lbl['drag-drop-file-here'] }}
                        </div>
                        <div class="text-2">
                          {{ lbl['file-format-excel-new-design'] }}
                        </div>
                        <a-button
                          class="btn-Upload-excel"
                          :disabled="loginType && isLoginTypeDevice"
                          @click="clickUpload()"
                        >
                          <img
                            :src="require('@/assets/images/Upload2.svg')"
                            alt=""
                            :draggable="false"
                          />
                          <span>{{ lbl['upload-excel-file'] }}</span>
                        </a-button>
                        <div
                          v-if="loginType && isLoginTypeDevice"
                          class="warning-wrapper"
                        >
                          <span>
                            {{ lbl['warning-unsupport-import-user'] }}
                          </span>
                        </div>
                        <div
                          v-if="loginType && !isLoginTypeDevice"
                          class="warning-wrapper"
                        >
                          <strong>
                            {{ lbl['warning-import-user-title'] }}
                          </strong>
                          <span>
                            {{ lbl['warning-import-user-desc'] }}
                          </span>
                        </div>
                        <input
                          ref="file"
                          type="file"
                          class="d-none"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          @change="handleChange"
                        />
                      </div>
                    </drop>
                  </div>
                  <div v-else class="wrapper-upload-excel-file">
                    <div class="w-50">
                      <div class="">
                        {{ lbl['uploading'] }}
                        <strong>{{ percentProgress }}%</strong>
                      </div>
                      <a-progress
                        :percent="percentProgress"
                        :show-info="false"
                        status="active"
                      />
                    </div>
                  </div>
                  <div
                    v-if="loginType && !isLoginTypeDevice"
                    class="text-download-tem"
                  >
                    <a
                      class="txt-dwnl-temp"
                      @click="
                        tagAnalytic('click_import_member_download_template')
                      "
                      :href="importUserTemplate"
                      download
                    >
                      <img
                        :src="require('@/assets/images/Download.svg')"
                        alt=""
                      />
                      {{ lbl['burn-download-temp'] }}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <!-- Validate Adjust File -->
            <div
              v-show="isUploadSuccess"
              class="col-xl-12 col-lg-12 col-md-12 custom-wrapper-dashboard"
            >
              <div class="title-wrapper">
                <div class="d-flex title-header">
                  <div @click="btnBackUpload()">
                    <img
                      class="button-arrow-back mr-3"
                      src="@/assets/images/userlevel/arrow_back.svg"
                      alt=""
                    />
                  </div>
                  {{ lbl['import-user-label'] }}
                </div>
                <notice
                  v-if="isBeeBanefit"
                  :messege="beeBenefitNoticeMessage"
                  icon="red_notice"
                />
              </div>

              <div class="box-validate-file mt-4">
                <div v-if="countSuccess > 0 || countUpdate > 0" class="row">
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="show-validate">
                      <div class="d-flex align-center">
                        <img
                          class="mr-2 img-import"
                          :src="
                            require('@/assets/images/importfile/done-circle.svg')
                          "
                          alt=""
                        />
                        <strong>{{ lbl['import-log-import-success'] }}</strong>
                      </div>

                      <div class="row mt-2">
                        <div
                          class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                        >
                          <div class="d-flex align-center">
                            <strong class="mr-2">{{ countSuccess }} </strong>
                            <div>{{ lbl['items'] }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="show-validate">
                      <div class="d-flex align-center">
                        <img
                          class="mr-2 img-import"
                          :src="
                            require('@/assets/images/importfile/update-time.svg')
                          "
                          alt=""
                        />
                        <strong>{{ lbl['import-log-import-update'] }}</strong>
                      </div>

                      <div class="row mt-2">
                        <div
                          class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                        >
                          <div class="d-flex align-center">
                            <strong class="mr-2">{{ countUpdate }} </strong>
                            <div>{{ lbl['items'] }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="show-validate">
                      <div class="d-flex align-center">
                        <img
                          class="mr-2 img-import"
                          :src="
                            require('@/assets/images/importfile/warning-circle.svg')
                          "
                          alt=""
                        />
                        <strong>{{ lbl['import-failed'] }}</strong>
                      </div>
                      <div class="row mt-2">
                        <div
                          class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                        >
                          <div class="d-flex align-center">
                            <strong class="mr-2">{{ countFailed }} </strong>
                            <div>{{ lbl['items'] }}</div>
                          </div>
                        </div>
                        <div
                          class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 text-right"
                        >
                          <a-button
                            type="primary"
                            :disabled="countFailed <= 0"
                            @click="exprotFailed"
                          >
                            <img
                              :src="
                                require('@/assets/images/importfile/download.svg')
                              "
                              alt=""
                              class="mr-2"
                            />
                            {{
                              lbl['download-file'] +
                              ' (' +
                              countFailed +
                              ' ' +
                              lbl['items'] +
                              ')'
                            }}
                          </a-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="row">
                  <div class="col-12">
                    <div class="show-validate">
                      <div class="d-flex align-center">
                        <img
                          class="mr-2 img-import"
                          :src="
                            require('@/assets/images/importfile/warning-circle.svg')
                          "
                          alt=""
                        />
                        <strong>{{ lbl['import-failed'] }}</strong>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <div class="d-flex align-center">
                            <strong class="mr-2">{{ countFailed }} </strong>
                            <div>{{ lbl['items'] }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="title">
                {{ lbl['import-list'] }}
                <a-badge
                  :count="countTotal"
                  :overflow-count="9999"
                  :number-style="{
                    backgroundColor: '#fff',
                    color: '#999',
                    boxShadow: '0 0 0 1px #d9d9d9 inset',
                  }"
                />
              </div>

              <div class="box-validate-table mt-2">
                <div class="filterContainer nopad mb-2">
                  <div :class="['filterMain', showFilterMain ? 'active' : '']">
                    <div class="filter" @click="toggle('showFilterMain')">
                      {{ lbl['user-permissions-filter'] }}
                      <img
                        :src="require('@/assets/images/filter_list.png')"
                        alt=""
                      />
                    </div>
                    <div class="spaceHover"></div>
                    <div class="filterDropdown">
                      <div class="filterLeft">
                        <div
                          :class="[
                            'filterList',
                            item === subFilter ? 'active' : '',
                          ]"
                          v-for="item in Object.keys(filter)"
                          @click="changeSubFilter(item)"
                          :key="item"
                        >
                          {{ lbl[filter[item].label] }}
                          <img
                            :src="require('@/assets/images/navigate_next.png')"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="filterRight">
                        <div
                          v-for="item in filter[subFilter].input"
                          :key="item"
                        >
                          <div class="filterList" @click="selectFilter(item)">
                            <CheckBox
                              :label="item"
                              :valuee="item"
                              :check="filter[subFilter].value"
                              type="filter"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="btnFilter">
                        <div @click="resetFilter()">
                          {{ lbl['user-permissions-reset'] }}
                        </div>
                        <button @click="handleFilter()">
                          {{ lbl['user-permissions-ok'] }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="filterItem"
                    v-for="(item, index) in Object.keys(allFilter)"
                    :key="'filter' + index"
                  >
                    <span v-if="allFilter[item] == 'success'">
                      {{ lbl['import-log-import-success'] }}
                      <img
                        @click="removeFilter(item)"
                        :src="require('@/assets/images/close.png')"
                        alt=""
                      />
                    </span>
                    <span v-else-if="allFilter[item] == 'update'">
                      {{ lbl['import-log-import-update'] }}
                      <img
                        @click="removeFilter(item)"
                        :src="require('@/assets/images/close.png')"
                        alt=""
                      />
                    </span>
                    <span v-else>
                      {{ lbl['import-failed'] }}
                      <img
                        @click="removeFilter(item)"
                        :src="require('@/assets/images/close.png')"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
                <a-table
                  :columns="isPH ? columnsPH : columns"
                  :data-source="importData"
                  rowKey="RowKey"
                  :pagination="{
                    pageSize: defaultPageSize,
                    defaultCurrent: 1,
                    hideOnSinglePage: false,
                  }"
                >
                  <template slot="isDate" slot-scope="text">
                    {{ text ? formatDate(text) : '' }}
                  </template>
                  <template slot="isdata" slot-scope="text">
                    {{ text ? text : '' }}
                  </template>
                  <template slot="active" slot-scope="text">
                    {{ text }}
                  </template>
                  <template slot="status" slot-scope="text, record">
                    <a-tag v-if="record.Type == 'Success'" color="green">
                      <span class="d-flex align-center">
                        <a-icon type="check-circle" class="mr-1" />
                        {{ lbl['import-log-import-success'] }}
                      </span>
                    </a-tag>
                    <a-tag v-else-if="record.Type == 'Update'" color="blue">
                      <span class="d-flex align-center">
                        <a-icon type="sync" class="mr-1" />
                        {{ lbl['import-log-import-update'] }}
                      </span>
                    </a-tag>
                    <a-tag v-else color="red">
                      <span class="d-flex align-center">
                        <a-icon type="close-circle" class="mr-1" />
                        {{ lbl['import-failed'] }}
                      </span>
                    </a-tag>
                  </template>
                  <template slot="point" slot-scope="text">
                    <span v-if="text != null" class="d-flex justify-end">
                      {{ text }}
                    </span>
                    <span v-else class="d-flex align-center justify-end">
                      <a-icon type="close" class="mr-1" />
                      <span class="color-red">{{ lbl['invalid-format'] }}</span>
                    </span>
                  </template>
                  <template slot="level" slot-scope="text">
                    <span class="d-flex justify-end">
                      {{ text ? text : '-' }}
                    </span>
                  </template>
                  <template slot="gender" slot-scope="text, record">
                    <span
                      v-if="record.Gender == 'male' || record.Gender == 'Male'"
                    >
                      {{ lbl['Male'] }}
                    </span>
                    <span
                      v-else-if="
                        record.Gender == 'female' || record.Gender == 'Female'
                      "
                    >
                      {{ lbl['Female'] }}
                    </span>
                    <span v-else>
                      {{ lbl['Other'] }}
                    </span>
                  </template>
                  <template slot="action" slot-scope="text, record">
                    <span v-if="record.Type == 'Update'">
                      <div
                        class="d-flex align-center cursor-pointer white-space-nowrap"
                        @click="removeUpdate(record)"
                      >
                        <a-icon type="close" class="mr-1" />
                        {{ lbl['user-level-button-remove'] }}
                      </div>
                    </span>
                  </template>
                </a-table>
              </div>

              <hr />
              <div class="row">
                <div class="col-12 d-flex justify-center">
                  <a-button class="btn-back px-5 mr-2" @click="btnBackUpload()">
                    <span>{{ lbl['btn_back'] }}</span>
                  </a-button>
                  <a-button
                    type="primary"
                    class="btn-submit px-5"
                    :disabled="countSuccess <= 0 && countUpdate <= 0"
                    @click="handleConfirmSubmit()"
                  >
                    <span>{{ lbl['btn-submit'] }}</span>
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModalUploadFile" class="overlay-modal-update-excel"></div>
    <div v-if="showModalUploadFile" class="custom-modal-upload-excel">
      <div class="d-flex">
        <div class="m-icon-info">
          <img :src="require('@/assets/images/info@2x.png')" alt="" />
        </div>
        <div>
          <div class="modal-upload-text-1">
            {{ lbl['adjust-confirm-text'] }}
          </div>
          <div class="modal-upload-text-2">
            {{ lbl['point-adjust-after-click-yes'] }}
          </div>
        </div>
      </div>
      <div class="text-right m-btn-group">
        <a-button class="m-btn-no" @click="calcelCallApi()">No</a-button>
        <a-button class="m-btn-yes" @click="callValidateAdjustFile()"
          >Yes</a-button
        >
      </div>
    </div>
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
      :desciption="alertModalSetting.desciption"
    />
    <ConfirmModal
      :isOpenModal="confirmModalSetting.isOpenModal"
      :modalType="confirmModalSetting.modalType"
      :title="confirmModalSetting.title"
      :details="confirmModalSetting.details"
      :backto="confirmModalSetting.backto"
      :onConfirm="confirmModalSetting.onConfirm"
      :onCancel="confirmModalSetting.onCancel"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import XLSX from 'xlsx'
import moment from 'moment'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsImport from '@/core/Import/service/BzbsImport'
import Header from '@/components/Layout/Header'
import { Drop } from 'vue-drag-drop'
import AlertModal from '@/components/Modal/Component/alertmodal2'
import ConfirmModal from '@/components/Modal/Component/confirmmodal2'
import Locale from '@/helper/locale.js'
import CheckBox from './components/CheckBox.vue'
import notice from './components/notice.vue'
export default {
  name: 'ImportCompanyUser',
  mixins: [Mixin],
  components: {
    Header,
    Drop,
    AlertModal,
    ConfirmModal,
    CheckBox,
    notice,
  },
  data: function () {
    return {
      currentLocale: 'th',
      isPH: Vue.bzbsConfig.businessRule.webFor.PH,
      fileReader: [],
      showModal: false,
      isAlreadyPoint: false,
      isAlreadyStamp: false,
      allowImportPoint: true,
      campaignmode: '',
      dispfile: false,
      errtype: false,
      erroverquota: false,
      errEmptyData: false,
      errData: false,
      errExitsName: false,
      disabledsubmit: true,
      excelFileName: '',
      errVerifyCode1Empty: false,
      errVerifyCode2Empty: false,
      errVerifyCode1Dup: false,
      errPointInvalid: false,
      errTelInvalid: false,
      errLevelInvalid: false,
      errVerifyCode2Change: false,
      successupload: false,
      showModalUploadFile: false,
      alertModalSetting: {
        title: '',
        details: '',
        modalType: '',
        isOpenModal: false,
        desciption: null,
      },
      confirmModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        backto: '',
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      importUserTemplate: '',
      loginType: null,
      appId: null,
      liffId: null,
      liffLanding: null,
      isProgress: false,
      percentProgress: 0,
      timeinterval: null,
      isUploadSuccess: false,
      countSuccess: 0,
      countFailed: 0,
      countTotal: 0,
      countUpdate: 0,
      beeBenefitNoticeMessage: '',
      isBeeBanefit: false,
      columns: [
        {
          title: 'Status',
          dataIndex: 'Type',
          key: 'import-log-status-header',
          align: 'left',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'User ID',
          dataIndex: 'ImportUserId',
          key: 'view-code-user-id',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Name',
          dataIndex: 'FirstName',
          key: 'user-permissions-name',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Last Name',
          dataIndex: 'LastName',
          key: 'user-permissions-lastname',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Verify Code 1',
          dataIndex: 'VerifyCode1',
          key: 'imp-usr-dtl-verf1',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Verify Code 2',
          dataIndex: 'VerifyCode2',
          key: 'imp-usr-dtl-verf2',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Tel Number',
          dataIndex: 'Tel',
          key: 'imp-usr-dtl-tel',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Email',
          dataIndex: 'Email',
          key: 'imp-usr-dtl-email',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Gender',
          dataIndex: 'Gender',
          key: 'imp-usr-dtl-gender',
          align: 'center',
          scopedSlots: { customRender: 'gender' },
        },
        {
          title: 'Birth Date',
          dataIndex: 'BirthDate',
          key: 'import-search-birthday',
          align: 'left',
          scopedSlots: { customRender: 'isDate' },
        },
        {
          title: 'Points',
          dataIndex: 'StrPoint',
          key: 'user-level-point-unit',
          scopedSlots: { customRender: 'point' },
        },
        {
          title: 'Level',
          dataIndex: 'LevelName',
          key: 'usr-level',
          align: 'left',
          scopedSlots: { customRender: 'level' },
        },
        {
          title: 'Remark',
          dataIndex: 'Message',
          key: 'remark',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: '',
          dataIndex: 'Type',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      columnsPH: [
        {
          title: 'Status',
          dataIndex: 'Type',
          key: 'import-log-status-header',
          align: 'left',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Name',
          dataIndex: 'FirstName',
          key: 'user-permissions-name',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Last Name',
          dataIndex: 'LastName',
          key: 'user-permissions-lastname',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Birth Date',
          dataIndex: 'BirthDate',
          key: 'import-search-birthday',
          align: 'left',
          scopedSlots: { customRender: 'isDate' },
        },
        {
          title: 'Gender',
          dataIndex: 'Gender',
          key: 'imp-usr-dtl-gender',
          align: 'center',
          scopedSlots: { customRender: 'gender' },
        },
        {
          title: 'Email',
          dataIndex: 'Email',
          key: 'imp-usr-dtl-email',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Tel',
          dataIndex: 'Tel',
          key: 'imp-usr-dtl-tel',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Active',
          dataIndex: 'Active',
          key: 'active',
          align: 'left',
          scopedSlots: { customRender: 'active' },
        },
        {
          title: 'Points',
          dataIndex: 'StrPoint',
          key: 'user-level-point-unit',
          scopedSlots: { customRender: 'point' },
        },
        {
          title: 'Verify Code 1',
          dataIndex: 'VerifyCode1',
          key: 'import-search-verifycode1',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Verify Code 2',
          dataIndex: 'VerifyCode2',
          key: 'import-search-verifycode2',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: 'Remark',
          dataIndex: 'Message',
          key: 'remark',
          align: 'left',
          scopedSlots: { customRender: 'isdata' },
        },
        {
          title: '',
          dataIndex: 'Type',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      resultData: [],
      importData: [],
      dataFailed: [],
      defaultPageSize: 10,
      rowKey: null,
      subFilter: 'status',
      showFilterMain: false,
      filter: {
        status: {
          value: null,
          input: ['success', 'failed', 'update'],
          label: 'import-log-status-header',
          type: 'checkbox',
        },
      },
      allFilter: {},
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'importuser' })
  },
  watch: {
    importData(value) {
      if (value.length > 0) {
        var hasVerifyCode3 = !value.every(item => item.VerifyCode3 == '')
        var hasVerifyCode4 = !value.every(item => item.VerifyCode4 == '')

        console.log('hasVerifyCode3::', hasVerifyCode3)
        console.log('hasVerifyCode4::', hasVerifyCode4)

        if (hasVerifyCode4) {
          this.columns = [
            {
              title: 'Status',
              dataIndex: 'Type',
              key: 'import-log-status-header',
              align: 'left',
              scopedSlots: { customRender: 'status' },
            },
            {
              title: 'User ID',
              dataIndex: 'ImportUserId',
              key: 'view-code-user-id',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Name',
              dataIndex: 'FirstName',
              key: 'user-permissions-name',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Last Name',
              dataIndex: 'LastName',
              key: 'user-permissions-lastname',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 1',
              dataIndex: 'VerifyCode1',
              key: 'imp-usr-dtl-verf1',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 2',
              dataIndex: 'VerifyCode2',
              key: 'imp-usr-dtl-verf2',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 3',
              dataIndex: 'VerifyCode3',
              key: 'import-search-verifycode3',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 4',
              dataIndex: 'VerifyCode4',
              key: 'import-search-verifycode4',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Tel Number',
              dataIndex: 'Tel',
              key: 'imp-usr-dtl-tel',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Email',
              dataIndex: 'Email',
              key: 'imp-usr-dtl-email',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Gender',
              dataIndex: 'Gender',
              key: 'imp-usr-dtl-gender',
              align: 'center',
              scopedSlots: { customRender: 'gender' },
            },
            {
              title: 'Birth Date',
              dataIndex: 'BirthDate',
              key: 'import-search-birthday',
              align: 'left',
              scopedSlots: { customRender: 'isDate' },
            },
            {
              title: 'Points',
              dataIndex: 'StrPoint',
              key: 'user-level-point-unit',
              scopedSlots: { customRender: 'point' },
            },
            {
              title: 'Level',
              dataIndex: 'LevelName',
              key: 'usr-level',
              align: 'left',
              scopedSlots: { customRender: 'level' },
            },
            {
              title: 'Remark',
              dataIndex: 'Message',
              key: 'remark',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: '',
              dataIndex: 'Type',
              align: 'center',
              scopedSlots: { customRender: 'action' },
            },
          ]
          this.columnsPH = [
            {
              title: 'Status',
              dataIndex: 'Type',
              key: 'import-log-status-header',
              align: 'left',
              scopedSlots: { customRender: 'status' },
            },
            {
              title: 'Name',
              dataIndex: 'FirstName',
              key: 'user-permissions-name',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Last Name',
              dataIndex: 'LastName',
              key: 'user-permissions-lastname',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Birth Date',
              dataIndex: 'BirthDate',
              key: 'import-search-birthday',
              align: 'left',
              scopedSlots: { customRender: 'isDate' },
            },
            {
              title: 'Gender',
              dataIndex: 'Gender',
              key: 'imp-usr-dtl-gender',
              align: 'center',
              scopedSlots: { customRender: 'gender' },
            },
            {
              title: 'Email',
              dataIndex: 'Email',
              key: 'imp-usr-dtl-email',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Tel',
              dataIndex: 'Tel',
              key: 'imp-usr-dtl-tel',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Active',
              dataIndex: 'Active',
              key: 'active',
              align: 'left',
              scopedSlots: { customRender: 'active' },
            },
            {
              title: 'Points',
              dataIndex: 'StrPoint',
              key: 'user-level-point-unit',
              scopedSlots: { customRender: 'point' },
            },
            {
              title: 'Verify Code 1',
              dataIndex: 'VerifyCode1',
              key: 'import-search-verifycode1',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 2',
              dataIndex: 'VerifyCode2',
              key: 'import-search-verifycode2',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 3',
              dataIndex: 'VerifyCode3',
              key: 'import-search-verifycode3',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 4',
              dataIndex: 'VerifyCode4',
              key: 'import-search-verifycode4',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Remark',
              dataIndex: 'Message',
              key: 'remark',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: '',
              dataIndex: 'Type',
              align: 'center',
              scopedSlots: { customRender: 'action' },
            },
          ]
        } else if (!hasVerifyCode4 && hasVerifyCode3) {
          this.columns = [
            {
              title: 'Status',
              dataIndex: 'Type',
              key: 'import-log-status-header',
              align: 'left',
              scopedSlots: { customRender: 'status' },
            },
            {
              title: 'User ID',
              dataIndex: 'ImportUserId',
              key: 'view-code-user-id',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Name',
              dataIndex: 'FirstName',
              key: 'user-permissions-name',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Last Name',
              dataIndex: 'LastName',
              key: 'user-permissions-lastname',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 1',
              dataIndex: 'VerifyCode1',
              key: 'imp-usr-dtl-verf1',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 2',
              dataIndex: 'VerifyCode2',
              key: 'imp-usr-dtl-verf2',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 3',
              dataIndex: 'VerifyCode3',
              key: 'import-search-verifycode3',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Tel Number',
              dataIndex: 'Tel',
              key: 'imp-usr-dtl-tel',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Email',
              dataIndex: 'Email',
              key: 'imp-usr-dtl-email',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Gender',
              dataIndex: 'Gender',
              key: 'imp-usr-dtl-gender',
              align: 'center',
              scopedSlots: { customRender: 'gender' },
            },
            {
              title: 'Birth Date',
              dataIndex: 'BirthDate',
              key: 'import-search-birthday',
              align: 'left',
              scopedSlots: { customRender: 'isDate' },
            },
            {
              title: 'Points',
              dataIndex: 'StrPoint',
              key: 'user-level-point-unit',
              scopedSlots: { customRender: 'point' },
            },
            {
              title: 'Level',
              dataIndex: 'LevelName',
              key: 'usr-level',
              align: 'left',
              scopedSlots: { customRender: 'level' },
            },
            {
              title: 'Remark',
              dataIndex: 'Message',
              key: 'remark',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: '',
              dataIndex: 'Type',
              align: 'center',
              scopedSlots: { customRender: 'action' },
            },
          ]
          this.columnsPH = [
            {
              title: 'Status',
              dataIndex: 'Type',
              key: 'import-log-status-header',
              align: 'left',
              scopedSlots: { customRender: 'status' },
            },
            {
              title: 'Name',
              dataIndex: 'FirstName',
              key: 'user-permissions-name',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Last Name',
              dataIndex: 'LastName',
              key: 'user-permissions-lastname',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Birth Date',
              dataIndex: 'BirthDate',
              key: 'import-search-birthday',
              align: 'left',
              scopedSlots: { customRender: 'isDate' },
            },
            {
              title: 'Gender',
              dataIndex: 'Gender',
              key: 'imp-usr-dtl-gender',
              align: 'center',
              scopedSlots: { customRender: 'gender' },
            },
            {
              title: 'Email',
              dataIndex: 'Email',
              key: 'imp-usr-dtl-email',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Tel',
              dataIndex: 'Tel',
              key: 'imp-usr-dtl-tel',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Active',
              dataIndex: 'Active',
              key: 'active',
              align: 'left',
              scopedSlots: { customRender: 'active' },
            },
            {
              title: 'Points',
              dataIndex: 'StrPoint',
              key: 'user-level-point-unit',
              scopedSlots: { customRender: 'point' },
            },
            {
              title: 'Verify Code 1',
              dataIndex: 'VerifyCode1',
              key: 'import-search-verifycode1',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 2',
              dataIndex: 'VerifyCode2',
              key: 'import-search-verifycode2',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 3',
              dataIndex: 'VerifyCode3',
              key: 'import-search-verifycode3',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Remark',
              dataIndex: 'Message',
              key: 'remark',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: '',
              dataIndex: 'Type',
              align: 'center',
              scopedSlots: { customRender: 'action' },
            },
          ]
        } else if (!hasVerifyCode4 && !hasVerifyCode3) {
          this.columns = [
            {
              title: 'Status',
              dataIndex: 'Type',
              key: 'import-log-status-header',
              align: 'left',
              scopedSlots: { customRender: 'status' },
            },
            {
              title: 'User ID',
              dataIndex: 'ImportUserId',
              key: 'view-code-user-id',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Name',
              dataIndex: 'FirstName',
              key: 'user-permissions-name',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Last Name',
              dataIndex: 'LastName',
              key: 'user-permissions-lastname',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 1',
              dataIndex: 'VerifyCode1',
              key: 'imp-usr-dtl-verf1',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 2',
              dataIndex: 'VerifyCode2',
              key: 'imp-usr-dtl-verf2',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Tel Number',
              dataIndex: 'Tel',
              key: 'imp-usr-dtl-tel',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Email',
              dataIndex: 'Email',
              key: 'imp-usr-dtl-email',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Gender',
              dataIndex: 'Gender',
              key: 'imp-usr-dtl-gender',
              align: 'center',
              scopedSlots: { customRender: 'gender' },
            },
            {
              title: 'Birth Date',
              dataIndex: 'BirthDate',
              key: 'import-search-birthday',
              align: 'left',
              scopedSlots: { customRender: 'isDate' },
            },
            {
              title: 'Points',
              dataIndex: 'StrPoint',
              key: 'user-level-point-unit',
              scopedSlots: { customRender: 'point' },
            },
            {
              title: 'Level',
              dataIndex: 'LevelName',
              key: 'usr-level',
              align: 'left',
              scopedSlots: { customRender: 'level' },
            },
            {
              title: 'Remark',
              dataIndex: 'Message',
              key: 'remark',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: '',
              dataIndex: 'Type',
              align: 'center',
              scopedSlots: { customRender: 'action' },
            },
          ]
          this.columnsPH = [
            {
              title: 'Status',
              dataIndex: 'Type',
              key: 'import-log-status-header',
              align: 'left',
              scopedSlots: { customRender: 'status' },
            },
            {
              title: 'Name',
              dataIndex: 'FirstName',
              key: 'user-permissions-name',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Last Name',
              dataIndex: 'LastName',
              key: 'user-permissions-lastname',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Birth Date',
              dataIndex: 'BirthDate',
              key: 'import-search-birthday',
              align: 'left',
              scopedSlots: { customRender: 'isDate' },
            },
            {
              title: 'Gender',
              dataIndex: 'Gender',
              key: 'imp-usr-dtl-gender',
              align: 'center',
              scopedSlots: { customRender: 'gender' },
            },
            {
              title: 'Email',
              dataIndex: 'Email',
              key: 'imp-usr-dtl-email',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Tel',
              dataIndex: 'Tel',
              key: 'imp-usr-dtl-tel',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Active',
              dataIndex: 'Active',
              key: 'active',
              align: 'left',
              scopedSlots: { customRender: 'active' },
            },
            {
              title: 'Points',
              dataIndex: 'StrPoint',
              key: 'user-level-point-unit',
              scopedSlots: { customRender: 'point' },
            },
            {
              title: 'Verify Code 1',
              dataIndex: 'VerifyCode1',
              key: 'import-search-verifycode1',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Verify Code 2',
              dataIndex: 'VerifyCode2',
              key: 'import-search-verifycode2',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: 'Remark',
              dataIndex: 'Message',
              key: 'remark',
              align: 'left',
              scopedSlots: { customRender: 'isdata' },
            },
            {
              title: '',
              dataIndex: 'Type',
              align: 'center',
              scopedSlots: { customRender: 'action' },
            },
          ]
        }

        this.setLocale()
      }
    },
  },
  computed: {
    isDisable() {
      if (this.loginType.includes('Line')) {
        if (this.appId && (this.liffLanding || this.liffId)) {
          return false
        } else {
          return true
        }
      } else {
        if (this.appId) {
          return false
        } else {
          return true
        }
      }
    },
    isLoginTypeDevice() {
      return this.loginType && this.loginType == 'Device' ? true : false
    },
  },
  mounted() {
    this.currentLocale = Locale.getLocaleShort()
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'import_member_page',
      'import_member',
      'view_member_import',
      'on view',
    )
    console.log('route : ', this.$route)
    var crmPackage = Account.getCachePackage()
    if (crmPackage && crmPackage.PackageId) {
      this.isBeeBanefit = crmPackage.PackageId.includes('benefit')
    }
    this.beeBenefitNoticeMessage =
      this.lbl['bee-benefit-import-user-warning-messege']
    if (this.$route.params.data) {
      this.isUploadSuccess = true
    }
    this.percentProgress = 0
    this.timeinterval = null
    this.isProgress = false
    this.setLocale()
    this.getCRMPlusProfile().then(result => {
      if (result.LoginType.includes('Line')) {
        this.importUserTemplate =
          Vue.bzbsConfig.bzbsBlobUrl +
          '/config/crmplus/ImportUserTemplate/import_company_user_line_template.xlsx'
      } else {
        this.importUserTemplate =
          Vue.bzbsConfig.bzbsBlobUrl +
          '/config/crmplus/ImportUserTemplate/import_company_user_email_template.xlsx'
      }

      this.loginType = result.LoginType
      this.appId = result.AppId
      this.liffId = result.LiffId
      this.liffLanding = result.LiffLanding
      if (typeof result.IsAlreadyPoint == 'boolean') {
        this.isAlreadyPoint = result.IsAlreadyPoint
      }
      if (typeof result.IsAlreadyStamp == 'boolean') {
        this.isAlreadyStamp = result.IsAlreadyStamp
      }
      if (typeof result.AllowImportPoint == 'boolean') {
        this.allowImportPoint = result.AllowImportPoint
      }
      if (this.isAlreadyPoint) {
        this.campaignmode = this.lbl['import-mode-point']
      } else {
        this.campaignmode = this.lbl['import-mode-stamp']
      }

      if (
        this.$router.currentRoute.path.includes('/Import/ImportUser/') &&
        !this.importData.length
      ) {
        window.location = '/Import/ImportUser'
      }
    })
  },
  methods: {
    setLocale() {
      _.forEach(this.columns, column => {
        column.title = this.lbl[column.key]
      })
    },
    tagAnalytic(events) {
      Account.bzbsAnalyticTracking(
        'import_member_page',
        'import_member',
        events,
        'on click',
      )
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('apiGetAppCategoryList error', error)
            resolve(error)
          })
      })
    },
    alertModalAction(
      title,
      details,
      modalType,
      isOpenModal,
      desciption,
      route,
      noreload,
    ) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      this.alertModalSetting.desciption = desciption
      if (modalType == 'success' && isOpenModal) {
        if (route) {
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
            this.$router.push({ name: route })
          }, 3000)
        } else {
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
        }
      } else {
        if (isOpenModal)
          if (noreload) {
            setTimeout(() => {
              this.alertModalSetting.isOpenModal = false
            }, 3000)
          } else {
            setTimeout(() => {
              location.reload()
              this.alertModalSetting.isOpenModal = false
            }, 3000)
          }
      }
    },
    confirmModalAction(
      title,
      details,
      modalType,
      backto,
      isOpenModal,
      onConfirm,
      onCancel,
    ) {
      this.confirmModalSetting.title = title
      this.confirmModalSetting.details = details
      this.confirmModalSetting.modalType = modalType
      this.confirmModalSetting.isOpenModal = isOpenModal
      this.confirmModalSetting.backto = backto
      this.confirmModalSetting.onConfirm = onConfirm
      this.confirmModalSetting.onCancel = onCancel
    },
    checkValidate(file) {
      this.clearerror()
      var isType = true
      const isSheet =
        file.type ==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const isExcel = file.type == 'application/vnd.ms-excel'
      if (!isSheet && !isExcel) {
        this.errtype = true
        isType = false
      }
      return isType
    },
    checkRowCompanyUserFile(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = e => {
          const bstr = e.target.result
          const wb = XLSX.read(bstr, { type: 'binary' })
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          let data = XLSX.utils.sheet_to_json(ws, { header: 1 })
          data = _.filter(data, item => {
            if (item.length != 0 && item != []) {
              return item
            }
          })
          if (data.length > 3001) {
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              this.lbl['you-upload-will-exceed-the-limit'],
              'error',
              true,
            )
          } else {
            this.isProgress = true
            this.countProgressTimer()
            BzbsImport.validateUploadCompanyUserFile(file)
              .then(res => {
                var result = res.data
                if (result.success) {
                  this.clearerror()
                  this.disabledsubmit = false
                  if (result.data.TotalCount == 0) {
                    this.$refs.file.value = null
                    this.dispfile = false
                    this.errData = true
                    let errorDetail = this.lbl['err-miss-adjust-data']
                    clearInterval(this.timeinterval)
                    this.alertModalAction(
                      this.lbl['alert-upload-error'],
                      errorDetail,
                      'error',
                      true,
                    )
                  } else {
                    this.excelFileName = result.data.FileName
                    this.rowKey = result.data.RowKey
                    this.dispfile = true
                    this.percentProgress = 100
                    clearInterval(this.timeinterval)
                    this.handleSubmitValidate(this.rowKey, result.data)
                  }
                } else {
                  this.clearerror()
                  this.$refs.file.value = null
                  let errorDetail = this.lbl['err-miss-adjust-data']
                  clearInterval(this.timeinterval)
                  this.alertModalAction(
                    this.lbl['alert-upload-error'],
                    errorDetail,
                    'error',
                    true,
                  )
                }
              })
              .catch(() => {
                let errorDetail = this.lbl['err-miss-adjust-data']
                clearInterval(this.timeinterval)
                this.alertModalAction(
                  this.lbl['alert-upload-error'],
                  errorDetail,
                  'error',
                  true,
                )
              })
          }
        }
        reader.readAsBinaryString(file)
      }
    },
    removeFileUpload() {
      this.$refs.file.value = null
      this.clearerror()
      this.disabledsubmit = true
    },
    clearerror() {
      this.dispfile = false
      this.errEmptyData = false
      this.erroverquota = false
      this.errtype = false
      this.errData = false
      this.errEmptyData = false
      this.errExitsName = false
      this.errData = false
      this.errEmptyData = false
      this.errVerifyCode1Empty = false
      this.errVerifyCode2Empty = false
      this.errVerifyCode1Dup = false
      this.errPointInvalid = false
      this.errTelInvalid = false
      this.errLevelInvalid = false
      this.errVerifyCode2Change = false
    },
    handleConfirmSubmit() {
      if (this.countFailed > 0) {
        this.confirmModalAction(
          this.lbl['confirm-message-import-include'],
          this.lbl['you-download-failed-list-import-again'],
          'warning',
          this.lbl['confirm-box-cancel-button'],
          true,
          () => {
            this.confirmModalSetting.isOpenModal = false
            this.handleSubmit()
          },
          () => {
            this.confirmModalSetting.isOpenModal = false
          },
        )
      } else {
        this.confirmModalAction(
          this.lbl['adjust-confirm-text'],
          this.lbl['point-adjust-after-click-yes'],
          'warning',
          this.lbl['confirm-box-cancel-button'],
          true,
          () => {
            this.confirmModalSetting.isOpenModal = false
            this.handleSubmit()
          },
          () => {
            this.confirmModalSetting.isOpenModal = false
          },
        )
      }
    },
    handleSubmit() {
      this.handleLoading(true)
      BzbsImport.confirmCompanyuserFile(this.rowKey)
        .then(res => {
          this.handleLoading(false)
          if (res.data.success) {
            this.successupload = true
            let desciption =
              this.countSuccess + this.countUpdate + ' ' + this.lbl['members']
            this.alertModalAction(
              this.lbl['alert-box-success-header'],
              this.lbl['importusersuccesstext'],
              'success',
              true,
              desciption,
              'ImportUserLog',
            )
          } else {
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              res.data.message
                ? res.data.message
                : this.lbl['Error occurred while processing your request.'],
              'error',
              true,
            )
          }
        })
        .catch(error => {
          this.handleLoading(false)
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            error.response.data.error.message
              ? error.response.data.error.message
              : this.lbl['Error occurred while processing your request.'],
            'error',
            true,
          )
        })
    },
    goto(page) {
      if (page == 'ImportUserLog') {
        Account.bzbsAnalyticTracking(
          'import_member_page',
          'import_member',
          'click_import_member_activity_log',
          'on click',
        )
      } else if (page == 'ImportUserSearch') {
        Account.bzbsAnalyticTracking(
          'import_member_page',
          'import_member',
          'click_import_member_search_imported',
          'on click',
        )
      }
      this.$router.push({ name: page })
    },
    formatDate(time) {
      if (typeof time == 'number') {
        return moment.unix(time).format('DD/MMM/YYYY')
      } else {
        try {
          var unix = moment(time, 'X')
          return moment.unix(unix).format('DD/MMM/YYYY')
        } catch {
          return ''
        }
      }
    },
    removeUpdate(record) {
      this.handleLoading(true)
      BzbsImport.removeCompanyuserFile(record.RowKey, record.PartitionKey)
        .then(res => {
          console.log('removeCompanyuserFile : ', res)
          this.countUpdate = parseInt(this.countUpdate) - 1
          this.handleLoading(false)
          this.removeData(record._id)
        })
        .catch(error => {
          this.handleLoading(false)
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            error.response.data.error.message
              ? error.response.data.error.message
              : this.lbl['Error occurred while processing your request.'],
            'error',
            true,
            null,
            null,
            true,
          )
        })
    },
    removeData(_id) {
      var index = this.importData
        .map(x => {
          return x._id
        })
        .indexOf(_id)

      this.importData.splice(index, 1)
    },

    //#region Upload
    handleDrop(data, event) {
      event.preventDefault()
      this.percentProgress = 0
      this.timeinterval = null
      this.isProgress = false
      this.handleChange({ target: { files: [event.dataTransfer.files[0]] } })
    },
    clickUpload() {
      Account.bzbsAnalyticTracking(
        'import_member_page',
        'import_member',
        'click_import_member_upload',
        'on click',
      )
      let fileInputElement = this.$refs.file
      fileInputElement.click()
    },
    handleChange(event) {
      if (this.checkValidate(event.target.files[0])) {
        this.checkRowCompanyUserFile(event.target.files[0])
      }
    },
    countProgressTimer() {
      this.timeinterval = setInterval(() => {
        if (this.percentProgress >= 99) {
          this.percentProgress = 99
          clearInterval(this.timeinterval)
        }
        if (this.percentProgress >= 89) {
          clearInterval(this.timeinterval)
        } else {
          let math = Math.floor(Math.random() * 20)
          this.percentProgress += math
          if (this.percentProgress >= 99) {
            this.percentProgress = 99
            clearInterval(this.timeinterval)
          }
        }
      }, 1000)
    },
    btnBackUpload() {
      this.percentProgress = 0
      this.timeinterval = null
      this.isProgress = false
      this.isUploadSuccess = false
      this.countSuccess = 0
      this.countFailed = 0
      this.countTotal = 0
      this.countUpdate = 0
      this.resultData = []
      this.importData = []
      this.$router.push({
        path: '/Import/ImportUser',
      })
    },
    handleSubmitValidate(rowKey, data) {
      console.log('Validate : ', data)
      this.countSuccess = data.SuccessCount
      this.countFailed = data.FailCount
      this.countUpdate = data.UpdateCount
      this.countTotal = data.TotalCount
      const mapData = data.Details.map(x => {
        return {
          VerifyCode1: x.VerifyCode1,
          VerifyCode2: x.VerifyCode2,
          VerifyCode3: x.VerifyCode3,
          VerifyCode4: x.VerifyCode4,
          FirstName: x.FirstName,
          LastName: x.LastName,
          BirthDate: x.BirthDate ? x.BirthDate : x.StrBirthDate,
          Gender: x.Gender,
          Email: x.Email,
          Tel: x.Tel,
          Active: x.Active,
          Point: x.Point,
          StrPoint: x.StrPoint,
          LevelName: x.LevelName,
          LevelValue: x.LevelValue,
          ImportUserId: x.ImportUserId,
          Success: x.Success,
          Message: x.Message,
          Type: x.Type,
          PartitionKey: x.PartitionKey,
          RowKey: x.RowKey,
          Timestamp: x.Timestamp,
          ETag: x.ETag,
          _id: x._id,
        }
      })
      this.resultData = mapData
      this.importData = mapData
      if (data.FailCount > 0) {
        this.dataFailed = _.filter(data.Details, item => {
          if (item.Message != null) {
            return item
          }
        })
      } else {
        this.dataFailed = []
      }

      setTimeout(
        () => (
          (this.isUploadSuccess = true),
          this.$router.push({
            path: '/Import/ImportUser/' + rowKey,
          })
        ),
        2000,
      )
    },
    exprotFailed() {
      console.log('exprotFailed')
      this.handleLoading(true)
      Account.bzbsAnalyticTracking(
        'import_user_page',
        'import_user_details',
        'click_import_user_download',
        'on click',
      )

      let data = _.map(this.dataFailed, item => {
        let bd = null
        if (
          item.BirthDate != null &&
          item.BirthDate != '-' &&
          item.BirthDate != ''
        ) {
          bd = moment(item.BirthDate).format('MM/DD/YYYY')
        } else {
          if (
            item.StrBirthDate != null &&
            item.StrBirthDate != '-' &&
            item.StrBirthDate != ''
          ) {
            bd = moment(item.StrBirthDate).format('MM/DD/YYYY')
          } else {
            bd = null
          }
        }

        var verifycode1 = 'verifycode1'
        var verifycode2 = 'verifycode2'
        var verifycode3 = 'verifycode3'
        var verifycode4 = 'verifycode4'
        var name = 'name'
        var lastname = 'lastname'
        var birthday = 'birthday'
        var gender = 'gender'
        var email = 'e-mail'
        var tel_number = 'tel number'
        var active = 'active'
        var point = 'point'
        var userlevel = 'userlevel'
        var remark = 'remark'

        return {
          [verifycode1]: item.VerifyCode1 ? item.VerifyCode1 : '',
          [verifycode2]: item.VerifyCode2 ? item.VerifyCode2 : '',
          [verifycode3]: item.VerifyCode3 ? item.VerifyCode3 : '',
          [verifycode4]: item.VerifyCode4 ? item.VerifyCode4 : '',
          [name]: item.FirstName ? item.FirstName : '',
          [lastname]: item.LastName ? item.LastName : '',
          [birthday]: bd ? bd : '',
          [gender]: item.Gender ? item.Gender : '',
          [email]: item.Email ? item.Email : '',
          [tel_number]: item.Tel ? item.Tel : '',
          [active]: item.Active,
          [point]: item.StrPoint ? item.StrPoint : '',
          [userlevel]: item.LevelName ? item.LevelName : '',
          [remark]: item.Message ? item.Message : '',
        }
      })

      var dataWS = XLSX.utils.json_to_sheet(data, { dense: true })
      var wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 12 },
        { wch: 12 },
        { wch: 20 },
        { wch: 12 },
        { wch: 12 },
        { wch: 12 },
        { wch: 15 },
        { wch: 15 },
      ]
      dataWS['!cols'] = wscols
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(
        wb,
        'CRMPlus_import_company_user_' +
          moment(new Date()).format('YYYY-MM-DD') +
          '.xlsx',
      )

      this.handleLoading(false)
    },
    toggle(data) {
      this[data] = !this[data]
    },
    changeSubFilter(type) {
      this.subFilter = type
    },
    selectFilter(item) {
      if (
        (this.filter[this.subFilter].value === 'success' &&
          item === 'success') ||
        (this.filter[this.subFilter].value === 'failed' && item === 'failed') ||
        (this.filter[this.subFilter].value === 'update' && item === 'update')
      ) {
        this.filter[this.subFilter].value = null
      } else {
        this.filter[this.subFilter].value = item
      }
    },
    resetFilter() {
      this.filter.status.value = ''
      this.handleFilter()
    },
    removeFilter(type) {
      Object.keys(this.filter).forEach(item => {
        if (type === item) this.filter[item].value = ''
      })
      this.handleFilter()
    },
    handleFilter() {
      this.showFilterMain = false
      let active = null
      if (this.filter.status.value === 'success') active = 'Success'
      if (this.filter.status.value === 'failed') active = 'Failed'
      if (this.filter.status.value === 'update') active = 'Update'

      this.allFilter = Object.keys(this.filter).reduce((acc, cur) => {
        if (!this.filter[cur].value) return acc
        return (acc = { ...acc, [cur]: this.filter[cur].value })
      }, {})

      if (active != null) {
        this.importData = _.filter(this.resultData, item => {
          if (item.Type == active) {
            return item
          }
        })
      } else {
        this.importData = this.resultData
      }
    },
    //#endregion Upload
  },
}
</script>

<style lang="scss">
@import '../ImportBatchPoint/AdjustPoint.scss';

.import-user-page {
  .color-red {
    color: $color-red-52;
  }
  .wrapper-upload-excel-file {
    height: 320px;
  }
  .ant-progress-inner {
    background-color: $color-grey-e0;
    border-radius: 0px;
    padding: 2px;
  }
  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: $primary;
    border-radius: 3px !important;
  }

  .button-arrow-back {
    border: solid 1px #757575;
    border-radius: 8px;
    padding: 8px 20px;
    align-items: center;
    justify-content: end;
    float: right;
    cursor: pointer;
    width: 65px;
  }

  .title-header {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: $text-dark;
  }

  .box-validate-file {
    .show-validate {
      padding: 16px;
      border-radius: 12px;
      border: solid 1px $color-grey-ee;
      background-color: white;
      margin-bottom: 10px;
      min-height: 132px;

      .img-import {
        width: 50px;
        height: 50px;
      }
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: $text-dark;
  }
  .btn-back,
  .btn-submit {
    height: 48px !important;
    font-size: 16px;
  }
}
</style>

<style lang="scss" scoped>
@import '../ImportBatchPoint/AdjustPointScoped.scss';
@import '@/style/main.scss';

.warning-wrapper {
  margin-top: 16px;
  font-size: 12px;
  color: #ff4d4f;
}
.custom-wrapper-dashboard {
  padding: 30px;
}
.wrapper-select-header {
  padding: 0 30px !important;
}
.around-content-import {
  padding: 14px 30px !important;
}
.txt-dwnl-temp {
  font-size: 16px;
  font-weight: bold;
  color: #f9a601;
}
.disable-btn {
  opacity: 0.5;
}
.title-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
